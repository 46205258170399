import {createSlice} from "@reduxjs/toolkit";


const initialState = {
  products: {
    payload: null
  },
};


const realtime_slice = createSlice({
  name: "realtime",
  initialState: initialState,

  reducers: {
    reset_products(state) {
      state.products.payload = null;
    },
    set_payload(state, action) {
      state.products.payload = action.payload;
    },
  },

});

export const {
  reset_products,
  set_payload,
} = realtime_slice.actions;

export const realtime = realtime_slice.reducer;
