import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  /** Выбранная пользователем компания при поиске по ИНН */
  selected_search_company: null,
};

const companies_slice = createSlice({
  name: "companies",
  initialState: initialState,

  reducers: {
    set_company_list(state, action) {
      state.company_list = action.payload
    },
    set_selected_search_company(state, action) {
      state.selected_search_company = action.payload;
    },
  },
});

export const {
  set_selected_search_company,
} = companies_slice.actions;

export const companies = companies_slice.reducer;
